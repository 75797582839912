import React from "react"
import { graphql, Link } from "gatsby"
import Markdown from "react-markdown"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ToTop from "../components/common/ToTop"
import Contact from "../components/home/Contact"

import "./blog-post.css"

const BlogPost = ({ data, pageContext, location }) => {
  const { strapiBlog: post } = data
  const { next, previous } = pageContext

  const dateNext = next ? new Date(next.PublishDate) : new Date()
  const datePrev = previous ? new Date(previous.PublishDate) : new Date()
  const date = new Date(post.PublishDate)

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  return (
    <Layout pathname={"/blog"}>
      <Seo
        article
        shareImage={post.image.formats.large.url}
        title={`${post.title} | SymLab`}
        description={post.excerpt}
        url={`/blog/${date.getFullYear()}/${post.slug}/`}
        date={post.PublishDate}
      />
      <div className="absolute top-0 h-96 bg-sym-gray w-full"></div>
      <article id="blog" className="prose lg:prose-xl">
        <div className="z-10 relative pt-28">
          <header className="blog-title">
            <h1 className="text-center font-bold text-2xl md:text-5xl text-white mt-6">
              {post.title}
            </h1>
          </header>
          <figure>
            <img
              className="mx-auto my-6"
              src={post.image.formats.large.url}
              alt={post.image.alternativeText}
            />
            <figcaption className="text-center italic mb-10">
              {post.image.caption || ""}
            </figcaption>
          </figure>
          <div
            id="blog-content"
            className="container mx-auto flex flex-wrap mt-10 px-6 justify-center"
          >
            <time className="text-right mb-10" dateTime={date}>
              {date.toLocaleDateString("es-ES", options)}
            </time>
            <Markdown children={post.content} skipHtml={false} />
          </div>
        </div>
        <div className="mx-auto mt-10 text-center" style={{ maxWidth: 32 }}>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=https://symlab.io${location.pathname}`}
          >
            <img
              src="/linkedin.svg"
              alt="LinkedIn"
              title="Compartir en LinkedIn"
              width={24}
            />
          </a>
        </div>
        <div
          className="grid grid-cols-3 text-center mx-auto mt-10"
          style={{ maxWidth: 700 }}
        >
          <div>
            {previous && (
              <Link
                className="inline-block"
                to={`/blog/${datePrev.getFullYear()}/${previous.slug}/`}
                style={{ maxWidth: "25%" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <span className="sr-only">Post Anterior</span>
              </Link>
            )}
          </div>
          <div>Ver más publicaciones</div>
          <div>
            {next && (
              <Link
                className="inline-block"
                to={`/blog/${dateNext.getFullYear()}/${next.slug}/`}
                style={{ maxWidth: "25%" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <span className="sr-only">Post Siguiente</span>
              </Link>
            )}
          </div>
        </div>
      </article>

      <Contact />
      <ToTop />
    </Layout>
  )
}

export const query = graphql`
  query PostsBySlug($slug: String!) {
    strapiBlog(slug: { eq: $slug }) {
      PublishDate
      author
      content
      excerpt
      image: cover_image {
        alternativeText
        caption
        formats {
          large {
            width
            url
          }
          medium {
            width
            url
          }
        }
      }
      slug
      status
      title
    }
  }
`

export default BlogPost
