import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ClickAwayListener from "react-click-away-listener"

import { goToID } from "../../utils/helpers"

const Header = ({ siteTitle, pathname }) => {
  const [openMenu, setOpenMenu] = useState(false)

  const handleClickAway = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <header className="absolute z-50 w-full flex flex-wrap flex-row justify-between items-center md:space-x-4 py-6 px-6">
        <div className="relative top-0">
          <Link to="/">
            <span className="sr-only">{siteTitle}</span>

            <img
              src="/logo/logo.svg"
              alt="SymLab Logo"
              title="SymLab"
              width="200"
            />
          </Link>
        </div>
        <button
          onClick={() => setOpenMenu(!openMenu)}
          className="inline-block md:hidden w-8 h-8 text-gray-600 p-1"
        >
          {openMenu ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
          <span className="sr-only">Open Menu</span>
        </button>
        <nav
          role="navigation"
          aria-label="Main"
          className={`transition-all duration-1000 ease-in-out transform flex ${
            openMenu
              ? "translate-x-0 opacity-100 w-full"
              : "translate-x-full opacity-0"
          } md:opacity-100 md:translate-x-0 absolute md:relative top-16 left-0 md:top-0 z-20  flex-col md:flex-row md:space-x-6 font-medium md:w-auto bg-white shadow-md rounded-lg md:rounded-none md:shadow-none md:bg-transparent p-8 pt-10 md:p-0 mt-8 md:mt-0`}
        >
          <Link
            to="/"
            className={`${
              pathname === "/"
                ? "text-sym-blue md:text-sym-light-blue"
                : "text-gray-600 md:text-white"
            } hover:underline`}
          >
            Home
          </Link>
          {pathname === "/" ? (
            <a
              href="#services"
              className="text-gray-600 md:text-white hover:underline"
              onClick={e => goToID(e, "#services")}
            >
              Servicios
            </a>
          ) : (
            <Link
              to="/#services"
              className="text-white md:text-white hover:underline"
            >
              Servicios
            </Link>
          )}
          {pathname === "/" ? (
            <a
              href="#projects"
              className="text-gray-600 md:text-white hover:underline"
              onClick={e => goToID(e, "#projects")}
            >
              Proyectos
            </a>
          ) : (
            <Link
              to="/#projects"
              className="text-white md:text-white hover:underline"
            >
              Proyectos
            </Link>
          )}
          {pathname === "/" ? (
            <a
              href="#clients"
              className="text-gray-600 md:text-white hover:underline"
              onClick={e => goToID(e, "#clients")}
            >
              Clientes
            </a>
          ) : (
            <Link
              to="/#clients"
              className="text-white md:text-white hover:underline"
            >
              Clientes
            </Link>
          )}
          {pathname === "/" ? (
            <a
              href="#contact"
              className="text-gray-600 md:text-white hover:underline"
              onClick={e => goToID(e, "#contact")}
            >
              Contacto
            </a>
          ) : (
            <Link
              to="/#contact"
              className="text-white md:text-white hover:underline"
            >
              Contacto
            </Link>
          )}

          <Link
            to="/blog/"
            className={`${
              pathname === "/blog/"
                ? "text-sym-blue md:text-sym-light-blue"
                : "text-gray-600 md:text-white"
            } hover:underline`}
          >
            Blog
          </Link>
        </nav>
      </header>
    </ClickAwayListener>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
