import React from "react"
import PropTypes from "prop-types"

const SectionHeader = ({
  title,
  subtitle,
  pretitle,
  titleColor,
  subtitleColor,
  pretitleColor,
}) => (
  <header>
    {pretitle !== "" && (
      <p
        className={`w-full px-6 md:px-0 md:w-1/2 mx-auto text-center text-2xl ${
          pretitleColor && `text-${pretitleColor}`
        }`}
      >
        {pretitle}
      </p>
    )}
    {title !== "" && (
      <h2
        className={`text-center font-bold text-5xl ${
          titleColor ? `text-${titleColor}` : `text-sym-gray`
        }`}
      >
        {title}
      </h2>
    )}
    {subtitle !== "" && (
      <p
        className={`text-center text-xl py-6 ${
          subtitleColor && `text-${subtitleColor}`
        }`}
      >
        {subtitle}
      </p>
    )}
  </header>
)

SectionHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  pretitle: PropTypes.string,
  pretitleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  titleColor: PropTypes.string,
}

SectionHeader.defaultProps = {
  pretitle: ``,
  title: ``,
  subtitle: ``,
  pretitleColor: ``,
  titleColor: ``,
  subtitleColor: ``,
}

export default SectionHeader
