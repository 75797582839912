/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./common/Header"
// import "./layout.css"

const Layout = ({ children, pathname }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="relative">
      <Header
        pathname={pathname}
        siteTitle={data.site.siteMetadata?.title || `Title`}
      />
      <div>
        <main id="home">{children}</main>
        <footer className="p-6 bg-sym-deep-purple text-center text-white">
          <img
            className="mx-auto mb-2"
            src="/foot-icon.svg"
            alt="Symlab Footer"
          />
          {/* <span>© {new Date().getFullYear()}</span> */}
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string,
}

Layout.defaultValues = {
  pathname: "/",
}

export default Layout
