import React, { useState } from "react"
import PropTypes from "prop-types"

import SectionHeader from "../common/SectionHeader"

import "./contact.css"

const Contact = ({ title }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setTelephone] = useState("")
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
  })
  const [isLoading, setLoading] = useState(false)
  const [contactError, setContactError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [contactMessage, setContactMessage] = useState("")

  const handleChange = e => {
    const value = e.target.value
    const type = e.target.id

    if (type === "name") {
      setName(value)
    }
    if (type === "email") {
      setEmail(value)
    }
    if (type === "phone") {
      setTelephone(value)
    }
    if (type === "message") {
      setMessage(value)
    }
  }

  const handleReset = () => {
    setName("")
    setEmail("")
    setMessage("")
    setTelephone("")
    setErrors({
      name: false,
      email: false,
      message: false,
    })
    setContactError(false)
  }

  const validateForm = data => {
    const { name, email, message } = data
    let contactMes = ""
    let valid = true
    const error = { name: false, email: false, message: false }
    // eslint-disable-next-line
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!name) {
      error.name = true
      contactMes = "Nombre, email y mensaje son requeridos"
      valid = false
      setContactError(true)
    }

    if (!email) {
      error.email = true
      contactMes = "Nombre, email y mensaje son requeridos"
      valid = false
      setContactError(true)
    }

    if (!message) {
      error.message = true
      contactMes = "Nombre, email y mensaje son requeridos"
      valid = false
      setContactError(true)
    }

    if (email && !re.test(email)) {
      error.email = true
      contactMes = "Por favor, verifica tu dirección de correo"
      valid = false
      setContactError(true)
    }

    setErrors(error)

    setContactMessage(contactMes)

    return valid
  }

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    setSuccess(false)
    const data = {
      name,
      email,
      telephone: phone,
      message,
    }

    if (!validateForm(data)) {
      setLoading(false)
      return
    }

    try {
      const response = await fetch(
        "https://xo0avwgnya.execute-api.us-east-1.amazonaws.com/dev/mail",
        {
          method: "post",
          body: JSON.stringify(data),
        }
      )
      if (!response.ok) {
        setLoading(false)
        setContactError(true)
        setContactMessage("Ha ocurrido un error por favor verifica tus datos")
      } else {
        setLoading(false)
        setContactError(false)
        setSuccess(true)
        setContactMessage(
          "Gracias por enviarnos tus datos en breve nos pondremos en contacto."
        )
        handleReset()
      }
    } catch (error) {
      setLoading(false)
      setContactError(true)
      setContactMessage("Ha ocurrido un error por favor verifica tus datos")
    }
  }

  return (
    <section id="contact" className="p-10 bg-gray-100 mt-20">
      <SectionHeader title={title} />
      <form
        className="container mx-auto bg-white mt-10 p-6"
        style={{ maxWidth: 700 }}
        onSubmit={onSubmit}
      >
        <label className="sr-only" htmlFor="name">
          Nombre
        </label>
        <input
          type="text"
          className={`mt-6 p-4 w-full bg-sym-light-purple 
                rounded-lg hover:bg-sym-light-blue 
                focus:outline-none focus:ring focus:border-sym-light-blue
                ${errors.name ? "border-2 border-red-300" : ""}
          `}
          placeholder="Nombre"
          id="name"
          name="name"
          value={name}
          onChange={handleChange}
        />
        <label className="sr-only" htmlFor="email">
          Email
        </label>
        <input
          type="email"
          className={`mt-6 p-4 w-full bg-sym-light-purple 
                rounded-lg hover:bg-sym-light-blue 
                focus:outline-none focus:ring focus:border-sym-light-blue
                ${errors.email ? "border-2 border-red-300" : ""}
          `}
          placeholder="Email"
          id="email"
          name="email"
          value={email}
          onChange={handleChange}
        />
        <label className="sr-only" htmlFor="phone">
          Teléfono
        </label>
        <input
          type="text"
          className={`mt-6 p-4 w-full bg-sym-light-purple 
                rounded-lg hover:bg-sym-light-blue 
                focus:outline-none focus:ring focus:border-sym-light-blue                
          `}
          placeholder="Teléfono"
          id="phone"
          name="phone"
          value={phone}
          onChange={handleChange}
        />
        <label className="sr-only" htmlFor="message">
          Message
        </label>
        <textarea
          className={`mt-6 p-4 w-full bg-sym-light-purple 
                rounded-lg hover:bg-sym-light-blue 
                focus:outline-none focus:ring focus:border-sym-light-blue
                ${
                  errors.message ? "border-2 border-red-300" : ""
                }               
          `}
          placeholder="Mensaje"
          id="message"
          name="message"
          value={message}
          onChange={handleChange}
        />
        <button
          className={`hidden md:block ${
            isLoading
              ? "bg-sym-purple"
              : "bg-gradient-to-b from-sym-purple to-sym-light-blue"
          } py-4 px-32 mt-6 rounded-full text-xl font-bold mx-auto text-white hover:from-sym-sym-purple hover:to-sym-purple`}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <span>
              <img
                className="inline mr-2"
                src="/puff.svg"
                style={{ height: 32 }}
                alt="Loader"
              />
              Enviando
            </span>
          ) : (
            "Enviar"
          )}
        </button>
        <p
          className={`mt-6 font-bold ${
            success ? "text-green-700" : "text-red-700"
          } text-center`}
        >
          {contactError && contactMessage}
          {success && "Gracias! Te contactaremos cuanto antes"}
        </p>
      </form>
      <div className="container mx-auto text-center mt-4">
        <p className="mb-2">
          Si prefieres escríbenos directamente a{" "}
          <a
            className="underline"
            href="mailto:info@symlab.io"
            title="info@symlab.io"
          >
            info@symlab.io
          </a>
        </p>
        <p>
          Tambien nos puedes encontrar en{" "}
          <a
            className="underline"
            href="https://www.linkedin.com/company/symmetricslab"
            title="SymLab - Linkedin"
            target="_blank"
            rel="noreferrer"
          >
            Linkedin
          </a>
        </p>
      </div>
    </section>
  )
}

Contact.propTypes = {
  title: PropTypes.string.isRequired,
}

Contact.defaultProps = {
  title: `Contacto`,
}

export default Contact
